

/* .note_container {
 width: 45%;
 max-width: 590px;
 height: 90%;
 max-height: 650px;
 display: flex;
 flex-direction: column;
 align-items:center;
 justify-content: flex-start;
 background-color: var(--notification-bg); 
 border-radius: 20px;
 
}
.note_container_rptd {
    height: 90% !important;
} */

.post_note_notices {
     /* background-color:antiquewhite;  */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content:space-around;
   
}

.post_note_count {   
    margin-left: 10px;    
    margin-right: 10px;
    font-weight: bolder;
    font-size: var(--txt-bold-gap);
    background-color:var(--notification-bg);
    color: var(--wh-text);
    width: 96%;
    border-radius:10px 3px;
    padding: 10px 0;
} 

.red_notice {
    background-color:red !important;
}
.lesson_header_one {
    color: var(--yellow-txt);
    font-size: var(--txt-bold);
    font-weight: 700;
    cursor:none;
    user-select: none;
    line-height: 45px;
    padding-top: 10px;
}
.lesson_header_two {
    color: var(--yellow-txt);
    font-size: var(--txt-bold);
    font-weight: 600;
    cursor:none;
    user-select: none;
    /* background-color:aquamarine; */
    margin: 35px 0;
}
.lesson_header_three {
    color: var( --training-input-bg);
    font-size: var(--txt-bold);
    font-weight: 400;
    cursor:none;
    user-select: none;
    /* background-color:aquamarine; */
    margin: 10px 0;
}

.results_box {
    width: 100%;    
    display: flex;
    flex-direction: row;
    align-items:  center;
    justify-content:center;   
}

.note_notice_item {
    display: flex;
    flex-direction: column;
    align-items:  center;
    justify-content:center;
    background-color: var(--yellow-txt);
    border-radius:10px 3px;
    padding: 3px 5px;
    color:var(--notification-bg);
    min-width: 21%;
}
.note_notice_item_color1 {    
    background-color: var(--green-bg);
}
.note_notice_item_color2 {    
    background-color: var(--info-bg);
}
.note_notice_item_color3 {    
    background-color: var(--bg-clr-1);
}

.note_notice_txt {
    font-size: var(--txt-bold-gap);
    font-weight: 400;
}
.note_notice_txt2 {   
    font-weight: 400;
}

.baloons {
    display: flex;
    flex-direction:row;
    justify-items: space-around;
    align-items:stretch;   
    width: 100%;
    height: 70vh;
    /* background-color:var(--info-bg); */
}

.stars_container {
    max-width: 580px;
    display: relative;
    /* background-color:rgb(100, 116, 111); */
}
.stars {  
    width: 100%;
    /* background-color:aquamarine; */
    display: flex;
    align-items:center;
    justify-content: space-around;
    margin-top: 30px;
}

.lower_btn_cont {
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
 margin-bottom: 20px;
}
.lower_rouned_btn {
    border-radius: 15px;   
}
.lower_rouned_btn_nerrow {
    min-width: 27%;
}
.lower_rouned_btn_wider {
    min-width: 60%;
}


.mid_section_box {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    font-weight: bold;
    font-size: var(--txt-midi);
   /* background-color:rgb(29, 108, 177); */
    color: var(--wh-text);   
}
/* 1480 */

.results_description {
    padding-top: 1em;
    padding-bottom: 1em;
    line-height: 15px;
    color: var(--wh-text);   
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
}
@media only screen and (min-width: 1480px) {
    .mid_section_box {    
        font-size: 22px;         
    }
    .results_description {
        padding-top: 0em;
        padding-bottom: 2em;
       
    }
}
.notice_line_cont {
    display:flex;
    flex-direction: row;
    /* background-color:blue; */
    width: 100%;
    max-width: 590px;
    align-items:center;
    justify-content: center;
    margin: auto;
}
.notice_line_txt {
    width: 55%;   
    text-align: end;
}
.notice_line_star {
    width: 45%;   
    text-align: center;
}
.notice_line_tpm {
    color: var(--yellow-txt);
    font-size: var(--txt-midi);
}
.notice_line_tpm_legend {
    font-size: var(--txt-bold-small);
}
.congrats {
    transition: all 0.8s ease;
    animation: congrat 4s ease-in-out infinite alternate;
    color: var(--yellow-txt)
}
@keyframes congrat {
    0% {
        transform:  scale(1);
    }    
    50% {
        transform:  scale(1.1);
   }
   100% {
        transform: scale(1);
   }
}
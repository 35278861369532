.key_board_container {
    width: 99vw;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    margin-top: 50px;
    position: relative;
}
.key_board_wrapper {
    width: 762px;
    height: 300px;
    /* background-color:cadetblue; */
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    cursor:not-allowed;
}
.key_board_row {
    height: 48px;
    width: 100%;
    margin-top: 10px;
    color: var(--darker-text);
    font-size: var(--txt-key_board);

    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: flex-start;
 /* background-color:antiquewhite; */
}
.key_board_btn {
    background-color:rgba(127, 255, 212, 0.426);
    width: 48px;
    height: 100%;
    border-radius:5px;
    margin-left: 5px;
    font-weight: 500;

    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content: center;
}
.key_board_abs_cont {
    position: relative;
    width: 53px;
    height: 48px;  
}
.key_board_abs_underline {
    position: absolute;
    bottom:2px;
    left:22px;
    font-weight:bolder;
    font-size: xx-large;
}
.key_board_btn1 {
    width: 61px !important;
}
.key_board_btn2 {
    width: 81px !important;
}
.key_board_btn3 {
    width: 108px !important;
}
.key_board_btn4 {
    width: 410px !important;
}
.low_row {
   justify-content: center !important;
}



.exp_btn {
    outline: solid 4px rgb(230, 8, 8);
    transform: scale(1.15) translateY(-5px);
    z-index: 5;
}
.keyboard_error {
    position: absolute;
    width: 762px;
    height: 295px;
    /* background-color:rgba(165, 42, 42, 0.348); */
    background-color:rgba(255, 0, 0, 0.392);
    border-radius:20px;
    bottom: 33px;
}
.show_controll{
    width: 762px;
    height: 30px;
    text-align: right;
   /* background-color:blueviolet; */
   cursor: pointer;
}




.logo_img {
  position: absolute;
    bottom: 5px;
    left: 10px;   
    width: 120px;
    height: 120px;
  
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    z-index: 5;
    transition: 1.3s ease-in-out;
}
.logo_img:hover{
    transform: scale(1.15) translateY(-10px) !important;
}


.gear_whele_abs_div {    
    position: absolute;
    top: 30px;
    right: 30px;   
    width: 50px;
    height: 50px;
    cursor:pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 2;
    transition: 0.8s ease-in-out;
}

.re_abs_div {    
    position: absolute;       
    width: 40px;
    height: 40px;
    cursor:pointer;
    display: flex;
    align-items:center;
    justify-content: center;    
    transition: 0.8s ease-in-out;
    border-radius: 50%;   
    font-size: 19px;
    font-weight: bolder;  
    z-index: 1;  
}
.pwr_icon { 
    top: 36px;
    right: 123px;         
}
.pwr2_icon { 
    left: 190px;      
}
.ppl_icon { 
    top: 36px;
    right: 210px;         
}
.graph_icon { 
    top: 36px;
    right: 305px;             
}
.graph2_icon { 
    top: calc(30vh + 100px);
    left: 190px;             
}
.re_icon {
    top: 36px;
    right: 400px;    
}
.re2_icon {
    top: calc(30vh + 200px);
    left: 190px;    
}


.re_abs_div_above {
    position: relative;
    padding-bottom: 2px;
}
.re_abs_div_above_txt {
    position: absolute;
    top: -16px;
    right: -18px;   
    width: 26px;
    height: 26px;  
    border-radius: 50%;
    outline: solid 2px;
    font-size: 15px;
    font-weight: bolder;   
    z-index: 5; 
    display: flex;
    align-items:center;
    justify-content: center;
    background-color:orange;
}


.lang_select_abs_div {    
    position: absolute;
    top: 95px;
    right: 90px;   
    width: 120px;
    height: 50px;
    cursor:pointer;
    display: flex;
    align-items:center;
    justify-content: center;
    z-index: 2;
    transition: 0.8s ease-in-out;
    background-color: transparent;
    border: none;
    font-size: 1.8em;
    color: #FFFFFF;   
}
.lang_box_bg {
    width: 90%;
    height: 100px;
    background-color:rgba(67, 86, 102, 0.658);
    border-radius:5px;
    padding: 60px 0 10px 0;
    margin-top: 20px;
}
.lang_select_abs_div option {
      border-radius:15px;
    background-color: transparent;
    color: #545353;
    font-size: 1.2em;
}
.gear_whele_abs_div:hover {
    transform: scale(1.20) translateX(-1px) !important;    
}
.re_abs_div:hover {
    transform: scale(1.20) translateX(-1px) !important;    
}
.gear_wheel {
    transform: rotate(180deg);    
    transition: 1s ease-in-out;
}
.gear_wheel_init {
    transform: rotate(0);
    transition: 1s ease-in-out;
}
 
.settings_wrapper {
    padding-top: 70px;
    padding-bottom: 30px;
    position: absolute !important;
    top: 0px;
    right: 0px;   
    width: 250px;
    height: 570px;
    background-color:var(--light-shade-bg);
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    z-index: 1;
    border-radius: 0 0 0 50px ;
    overflow-y: auto;
    transition: 1s ease-in-out;
}

.hide_wrapper {
    right: -250px;
}
.show_wrapper {
    right: 0px;
}
.displayNone {
    display: none;
}
.rel_blck {
    position: relative;
}
.menu_header {
    margin: 20px auto;
    font-weight: 400;
    position: sticky; 
    top: 0;
    z-index: 1;
    background-color:rgba(67, 86, 102, 0.658);
    width: 210px;
    height: 40px;
    padding: 10px 0 10px 0;
    border-radius:5px;
}
.bg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    transform: scale(1.00) translateX(-0px) !important;

    width: 200px;
    min-height: 50px;
    cursor: pointer;
    margin-bottom: 15px;

    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    font-size: larger;

    border-radius:10px;
}

.outlined {
    outline: solid 2px white !important;
    transform: scale(1.05) translateX(-5px) !important;
    font-size: large;
    transition: all 0.3s ease-in-out;
}

.action_btn {   
    animation: change_colour 40s ease-in-out infinite;
    outline:none;
}
@keyframes change_colour {
    0% {
      background-color: orange ;
    }
    20% {
      background-color: orangered ;
      color:#FFFFFF;
      box-shadow: -5px -5px 15px rgb(255,255,255);     
    }
    40% {
      background-color: goldenrod ;
      color:blue
    }
    60% {
      background-color: #FFFFFF ;
      color: orangered;
      box-shadow: 5px 5px 15px rgb(255,255,255); 
    }
    80% {
      background-color: violet ;
      color:#FFFFFF;
    }
    100% {
      background-color: var(--green-btn) ;
    }
  }
.screen {
    display: flex;
    flex-direction: column;
    align-items:center;
    position: relative;
    height: 100vh;
    overflow: hidden;
}
.training_input {
    width: 100vw;
    /* max-width: 1500px; */
    height: 80px;
    margin-top: 50px;
    /* border-radius:5px; */
    outline: none;   

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-size: var(--txt-bold);
    font-weight: 400;
    color:black;
    letter-spacing: 1px;
}


.training_input_wrapper {
    width: 100vw;
   display: flex;
   align-items:center;
   justify-content: center;
}

@keyframes cursor {
    0% {opacity: 0.9;}  
    100% {opacity: 0; }
}

.cursor {
    animation: cursor 1.1s infinite; 
    background-color:black;
    width: 2px;
    height: 50px;
}

.typing_input {
    width: 1px;
    height: 1px;   
    background-color: var(--training-input-bg);
    position: absolute;
    z-index: -100;
    text-transform: lowercase;
}
.invi_txt {
    color:rgba(240, 255, 255, 0);
}
.gray_txt {
    color: rgb(157, 157, 157);
}

.note_wrapper {
 width: 100%; 
 height: 100vh;
 display: flex;
 align-items:center;
 justify-content: center; 
 overflow: hidden;
 position: relative;
}

.note_container {
 width: 45%;
 max-width: 590px;
 min-height: 85%;
 /* max-height: 550px; */
 display: flex;
 flex-direction: column;
 align-items:center;
 justify-content: space-between;
 background-color: var(--notification-bg); 
 border-radius: 20px;
 padding-top: 30px;
 /* background-color:antiquewhite; */
}

.post_note_container {
    width: 45%;
    max-width: 590px;
    height: 75%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color:var(--notification-bg); 
    border-radius: 20px;
   
}
.note_container_lower_btn {
    width: 100%;
    padding-bottom: 40px;
}

.post_note_notices {
    
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.post_note_notices {
    /* background-color:rgb(241, 149, 28); */
    display: flex;
    flex-direction: row;   
    justify-content:space-around;
}
.post_note_count {   
    margin-left: 10px;    
    margin-right: 10px;
    font-weight: bolder;
    font-size: var(--txt-bold-gap);
    background-color:var(--notification-bg);
    color: var(--wh-text);
    width: 96%;
    border-radius:10px 3px;
    padding: 10px 0;
}
.red_notice {
    background-color:red !important;
}


.div_80 {
    width: 100%;
    height: 80%;
    padding-bottom: 35px;
}
.div_70vh {   
    height: 70vh;
    width: 100vw;    
}

.div_20 {
    height: 20%;
    width: 100%;
}
.lesson_header_one {
    color: var(--yellow-txt);
    font-size: var(--txt-black);
    font-weight: 800;
    cursor:none;
    user-select: none;
}
.lesson_header_two {
    color: var(--yellow-txt);
    font-size: var(--txt-bold);
    font-weight: 600;
}

.lesson_header_cont {
    width: 150px;    
    display: flex;
    align-items:center;    
}
.rouned_btn {
    border-radius: 15px;
    width: 60%;
}
.footer_btn_cont {
    position: absolute;
    bottom:20px;
    width: 100%;      
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;   
}
.footer_btn_box {
    width: 762px; 
    /* background-color:rgb(68, 52, 30); */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btm_btn_one {
    border-radius: 15px;
    width: 30%;
}

.results_box {   
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items:  flex-end;
    justify-content:center;
}

.note_notice_item {
    display: flex;
    flex-direction: column;
    align-items:  center;
    justify-content:center;
    background-color: var(--yellow-txt);
    border-radius:10px 3px;
    padding: 3px 5px;
    color:var(--notification-bg);
}
.note_notice_item_color1 {    
    background-color: var(--green-bg);
}
.note_notice_item_color2 {    
    background-color: var(--info-bg);
}

.note_notice_txt_container {
    padding: 15px 35px;
}
.note_notice_txt {
    font-size: var(--txt-bold-gap);
    font-weight: 400;
}
.note_notice_txt2 {   
    font-weight: 400;
}
.rotate_block {
    transform: rotate(25deg); 
    overflow: hidden;
}

.baloons {
    display: flex;
    flex-direction:row;
    justify-items: space-around;
    align-items:stretch;
    color:cadetblue;
    width: 100%;
    height: 70vh;
    /* background-color:var(--info-bg); */
}


.stars_container {
    width: 580px;
    display: relative;
    /* background-color:rgb(100, 116, 111); */
}
.stars {
  
    width: 100%;
    /* background-color:aquamarine; */
    display: flex;
    align-items:center;
    justify-content: space-around;
}

.lower_btn_cont {
 width: 100%;
 display: flex;
 flex-direction: row;
 justify-content: space-around;
}
.lower_rouned_btn {
    border-radius: 15px;
   
}
/* width:762px; */
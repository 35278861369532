.action_btn {
    border-radius: var(--border-rad);
    animation: change_colour 20s ease-in-out infinite;
}
@keyframes change_colour {
    0% {
      background-color: var(--green-btn) ;
    }
    20% {
      background-color: yellow ;
      color:blue;
      box-shadow: -5px -5px 15px rgb(255,255,255);     
    }
    40% {
      background-color: goldenrod ;
      color:blue
    }
    60% {
      background-color: blue ;
      color: #FFFFFF;
      box-shadow: 5px 5px 15px rgb(255,255,255); 
    }
    80% {
      background-color: violet ;
    }
    100% {
      background-color: var(--green-btn) ;
    }
  }

.ballast {
    height: 500px;
}

.captcha_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* background-color:bisque; */
    align-items: center;
    justify-content: center;
}
.captcha_wrapper {
    display: flex;
    flex-direction: row;
    /* background-color:bisque; */
    align-items: center;
    justify-content: flex-start;
    transition: 0.5s ease-in;
    /* animation: move-screen-in 0.5s linear;  */
}
.captcha_wrapper_invis {
 /* animation: move-screen-out 1s linear;  */
 /* position:absolute;
 bottom: 10%;
 z-index: -100;
 transform:translateX(100%);
 opacity: 0;*/
 transition: 0.5s ease-out; 
 opacity: 0;
}
/* @keyframes move-screen-out {
    from {
        x:0;
    }
    to {
        x:-100%;
        y: -100%;
        opacity: 0;
        scale: 0;
    }
}
@keyframes move-screen-in {
    from {
        x:1000;
    }
    to {
        x:0;
    }
} */

/* 'ml-md-3 mt-3 mt-md-0' */
.input_container {
 margin-left: 0px;
 display: flex;
 flex-direction: column;
 justify-content:space-between;
 padding: 5px 0;
}
@media screen and (max-width: 470px) {
    .captcha_wrapper {
        flex-direction: column;
    }
    .input_container {
        margin-top: 2rem;
        margin-left: 0;
    }
}
.captcha_img_box {
    position: relative;    
    width: 175px;
}
.captcha_txt {
    position:absolute;
    top:10px;
    left: 0;
    height: 100%;   
    display: flex;
    flex-direction: column;
   cursor: none;
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none;   /* Chrome/Safari/Opera */
   -khtml-user-select: none;    /* Konqueror */
   -moz-user-select: none;      /* Firefox */
   -ms-user-select: none;       /* Internet Explorer/Edge */
   user-select: none;
   font-size: 40px;
   font-weight: 500;
   letter-spacing: 5px;
   transform: rotate(25deg);
   filter:blur(1px);
   color:#c3c3c3;
   font: MonoSpace;
   text-shadow: 1px 3px 5px #000000;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items:center;
   justify-content: center;
   
}


.go_btn {
    
     height:  40px;
     padding: 0;    
     border-radius: 25px;   
     width:  80px;   
     font-size:  14px;
}
.renew_btn {
    height:  35px;
    padding: 0;
    border-radius: 10px;
    width:  100%;
    font-size: 10px;
}
.value_input {
     height:  35px;
     padding: 0;    
     border-radius: 10px;   
     width: 150px;   
     font-size: var(--txt-normal);
     background-color:rgb(250, 250, 250);    
     color: blueviolet;        
     border: none;
     outline: none;
     text-align: center;
}
.img {
    height: 180px;
    padding: 0 15px 10px 0;
}











/* .go_btn {
    background:  transparent;
     height:  38px;
     line-height:  40px;
     border:  2px solid white;
     border-radius: 25px;
     display:  inline-block;
     float:  none;
     text-align:  center;
     width:  120px;
     padding: none;
     font-size:  14px;
     color:  #fff;
     cursor:pointer;
     outline:none;
     overflow: hidden;
     align-items: center;
}
.go_btn :hover  {
    color:  #fff;
    background:  rgba(255, 255, 255, 0.2);
} */
.bg_olive {
    background-color:rgba(127, 255, 212, 0.426) !important; 
 }
.bg_green {
    background-color: var(--training-input-bg) !important;
}
.bg_blue {
    background-color: var(--btn-bg-blue) !important;
}
.bg_roze {
    background-color: var(--btn-bg-roze) !important;
}
.bg_orange {
    background-color: var(--btn-bg-orange) !important;
}
.bg_yellow {
    background-color: var(--btn-bg-yellow) !important;
}



.bg_blue2 {
    background-color:rgba(80, 80, 184, 0.936) !important;
}
.bg_roze2 {
    background-color: rgba(200, 75, 73, 0.936) !important;
}
.bg_orange2 {
    background-color: rgba(208, 126, 53, 0.936) !important;
}
.bg_yellow2 {
    background-color: rgba(227, 227, 72, 0.854) !important;
}



.bg_blue3 {
    background-color:rgba(80, 80, 184, 0.936) !important;
}
.bg_roze3 {
    background-color: rgba(200, 75, 73, 0.936) !important;
}
.bg_orange3 {
    background-color: rgba(208, 126, 53, 0.936) !important;
}
.bg_yellow3 {
    background-color: rgba(227, 227, 72, 0.604) !important;
}
.bg_green3 {
    background-color: var( --training-input-darker-bg) !important;
}
.bg_olive3 {
   background-color:rgba(127, 255, 212, 0.281); 
}

.bg_blue4 {
    background-color:rgba(80, 80, 184, 0.936) !important;
}
.bg_roze4 {
    background-color: rgba(200, 75, 73, 0.936) !important;
}
.bg_orange4 {
    background-color: rgba(208, 126, 53, 0.936) !important;
}
.bg_yellow4 {
    background-color: rgba(227, 227, 72, 0.604) !important;
}
.bg_green4 {
    background-color: var( --training-input-darker-bg) !important;
}
.bg_olive4 {
   background-color:rgba(113, 220, 184, 0.398); 
}


.bg_olive5 {
    background-color:rgba(122, 255, 211, 0.398); 
 }



 .bg_blue6 {
    background-color:#382fbb !important;
}
.bg_roze6 {
    background-color: #c53069 !important;
  
}
.bg_orange6 {
    background-color: rgba(223, 124, 38, 0.936) !important;
}
.bg_yellow6 {
    background-color: rgba(227, 227, 72, 0.856) !important;
}
.bg_green6 {
    background-color: var( --training-input-bg) !important;
}
.bg_olive6 {
   background-color:rgba(127, 255, 212, 0.758); 
}










.training_input_bg {
    background-color: var(--training-input-bg);
}
.training_input_bg3 {
    background-color: var(--training-input-darker-bg);
}




.shade_scr_vert_line_bg1 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Sunset.jpg) no-repeat center center fixed;    */
    background: rgb(244,98,23);
background: linear-gradient(180deg, rgba(244,98,23,1) 0%, rgba(13,72,106,1) 100%);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.shade_scr_vert_line_bg2 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Titanium.jpg) no-repeat center center fixed;    */
    background: rgb(133,147,152);
background: linear-gradient(180deg, rgba(133,147,152,1) 0%, rgba(40,48,72,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.shade_scr_vert_line_bg3 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/MidnightCity.jpg) no-repeat center center fixed;    */
    background: rgb(65,67,69);
background: linear-gradient(180deg, rgba(65,67,69,1) 0%, rgba(35,37,38,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.shade_scr_vert_line_bg4 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Lawrencium.jpg) no-repeat center center fixed;    */
    background: rgb(36,36,62);
background: linear-gradient(180deg, rgba(36,36,62,1) 0%, rgba(47,42,97,1) 50%, rgba(15,12,42,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.shade_scr_vert_line_bg5 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Frost.jpg) no-repeat center center fixed;    */
    background: rgb(0,77,145);
background: linear-gradient(180deg, rgba(0,77,145,1) 0%, rgba(0,4,40,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.shade_scr_vert_line_bg6 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Celestial.jpg) no-repeat center center fixed;    */
    background: rgb(29,38,112);
background: linear-gradient(180deg, rgba(29,38,112,1) 0%, rgba(194,55,100,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.shade_scr_vert_line_bg7 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/LemonTwist.jpg) no-repeat center center fixed;    */
    background: rgb(60,165,92);
    background: linear-gradient(90deg, rgba(60,165,92,1) 0%, rgba(181,172,73,1) 100%);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}



.bg_1 {
    /* background: url(../assets/Sunset.jpg) no-repeat center center fixed !important; */
    background: rgb(244,98,23);
background: linear-gradient(180deg, rgba(244,98,23,1) 0%, rgba(13,72,106,1) 100%);

    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    transform: scale(1.00) translateX(-0px) !important;
   }
  .bg_2 {
   /* background: url(../assets/Titanium.jpg) no-repeat center center fixed !important; */
   background: rgb(133,147,152);
background: linear-gradient(180deg, rgba(133,147,152,1) 0%, rgba(40,48,72,1) 100%);
   -webkit-background-size: cover !important;
   -moz-background-size: cover !important;
   -o-background-size: cover !important;
   background-size: cover !important;
   
  }
  .bg_3 {
    /* background: url(../assets/MidnightCity.jpg) no-repeat center center fixed !important; */
    background: rgb(65,67,69);
background: linear-gradient(180deg, rgba(65,67,69,1) 0%, rgba(35,37,38,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    transform: scale(1.00) translateX(-0px) !important;
   }
   .bg_4 {
    /* background: url(../assets/Lawrencium.jpg) no-repeat center center fixed !important; */
    background: rgb(36,36,62);
background: linear-gradient(180deg, rgba(36,36,62,1) 0%, rgba(47,42,97,1) 50%, rgba(15,12,42,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
   }
   .bg_5 {
    /* background: url(../assets/Frost.jpg) no-repeat center center fixed !important; */
    background: rgb(0,77,145);
background: linear-gradient(180deg, rgba(0,77,145,1) 0%, rgba(0,4,40,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
   }
   .bg_6 {
    /* background: url(../assets/Celestial.jpg) no-repeat center center fixed !important; */
    background: rgb(29,38,112);
background: linear-gradient(180deg, rgba(29,38,112,1) 0%, rgba(194,55,100,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
   }
   .bg_7 {
    /* background: url(../assets/LemonTwist.jpg) no-repeat center center fixed !important; */
    background: rgb(60,165,92);
    background: linear-gradient(90deg, rgba(60,165,92,1) 0%, rgba(181,172,73,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
   }
   .bg_8 {
    /* background: url(../assets/RedOcean.jpg) no-repeat center center fixed !important; */
    background: rgb(29,66,79);
    background: linear-gradient(90deg, rgba(29,66,79,1) 0%, rgba(164,57,50,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
   }
   .shade_scr_vert_line_bg8 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/RedOcean.jpg) no-repeat center center fixed;    */
    background: rgb(29,66,79);
    background: linear-gradient(90deg, rgba(29,66,79,1) 0%, rgba(164,57,50,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_9 {
    /* background: url(../assets/LoveCouple.jpg) no-repeat center center fixed !important; */
    background: rgb(58,97,134);
    background: linear-gradient(90deg, rgba(58,97,134,1) 0%, rgba(136,37,62,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg9 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/LoveCouple.jpg) no-repeat center center fixed;    */
    background: rgb(58,97,134);
    background: linear-gradient(90deg, rgba(58,97,134,1) 0%, rgba(136,37,62,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_10 {
    /* background: url(../assets/Dawn.jpg) no-repeat center center fixed !important; */
    background: rgb(243,144,79);
    background: linear-gradient(90deg, rgba(243,144,79,1) 0%, rgba(59,67,113,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg10 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Dawn.jpg) no-repeat center center fixed;    */
    background: rgb(243,144,79);
    background: linear-gradient(90deg, rgba(243,144,79,1) 0%, rgba(59,67,113,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}
.bg_11 {
    /* background: url(../assets/Roseanna.jpg) no-repeat center center fixed !important; */
    background: rgb(255,175,189);
    background: linear-gradient(90deg, rgba(255,175,189,1) 0%, rgba(255,195,160,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg11 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Roseanna.jpg) no-repeat center center fixed;    */
    background: rgb(255,175,189);
    background: linear-gradient(90deg, rgba(255,175,189,1) 0%, rgba(255,195,160,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_12 {
    /* background: url(../assets/CocoaaIce.jpg) no-repeat center center fixed !important; */
    background: rgb(192,192,170);
    background: linear-gradient(90deg, rgba(192,192,170,1) 0%, rgba(29,239,255,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg12 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/CocoaaIce.jpg) no-repeat center center fixed;    */
    background: rgb(192,192,170);
    background: linear-gradient(90deg, rgba(192,192,170,1) 0%, rgba(29,239,255,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_13 {
    /* background: url(../assets/RoseColoredLenses.jpg) no-repeat center center fixed !important; */
    background: rgb(231,203,192);
    background: linear-gradient(90deg, rgba(231,203,192,1) 0%, rgba(99,111,164,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg13 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/RoseColoredLenses.jpg) no-repeat center center fixed;    */
    background: rgb(231,203,192);
    background: linear-gradient(90deg, rgba(231,203,192,1) 0%, rgba(99,111,164,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_14 {
    /* background: url(../assets/Coal.jpg) no-repeat center center fixed !important; */
    background: rgb(235,87,87);
    background: linear-gradient(90deg, rgba(235,87,87,1) 0%, rgba(132,34,54,1) 50%, rgba(0,0,0,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg14 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Coal.jpg) no-repeat center center fixed;    */
    background: rgb(235,87,87);
    background: linear-gradient(90deg, rgba(235,87,87,1) 0%, rgba(132,34,54,1) 50%, rgba(0,0,0,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_15 {
    /* background: url(../assets/VisionsOfGrandeur.jpg) no-repeat center center fixed !important; */
    background: rgb(0,0,70);
    background: linear-gradient(90deg, rgba(0,0,70,1) 0%, rgba(28,180,223,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg15 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/VisionsOfGrandeur.jpg) no-repeat center center fixed;    */
    background: rgb(0,0,70);
    background: linear-gradient(90deg, rgba(0,0,70,1) 0%, rgba(28,180,223,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_16 {
    /* background: url(../assets/KingYna.jpg) no-repeat center center fixed !important; */
    background: rgb(31,41,105);
    background: linear-gradient(90deg, rgba(31,41,105,1) 0%, rgba(132,34,54,1) 50%, rgba(236,151,42,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg16 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/KingYna.jpg) no-repeat center center fixed; */  
    background: rgb(31,41,105);
    background: linear-gradient(90deg, rgba(31,41,105,1) 0%, rgba(132,34,54,1) 50%, rgba(236,151,42,1) 100%);
    -webkit-background-size: cover; 
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_17 {
    /* background: url(../assets/Selenium.jpg) no-repeat center center fixed !important; */
    background: rgb(60,59,63);
    background: linear-gradient(90deg, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg17 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
   /* background: url(../assets/Selenium.jpg) no-repeat center center fixed !important; */
   background: rgb(60,59,63);
   background: linear-gradient(90deg, rgba(60,59,63,1) 0%, rgba(96,92,60,1) 100%);  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_18 {
    /* background: url(../assets/SandToBlue.jpg) no-repeat center center fixed !important; */
    background: rgb(62,81,81);
    background: linear-gradient(90deg, rgba(62,81,81,1) 0%, rgba(221,202,164,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg18 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/SandToBlue.jpg) no-repeat center center fixed;    */
    background: rgb(62,81,81);
    background: linear-gradient(90deg, rgba(62,81,81,1) 0%, rgba(221,202,164,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_19 {
    /* background: url(../assets/Quepal.jpg) no-repeat center center fixed !important; */
    background: rgb(17,153,142);
    background: linear-gradient(90deg, rgba(17,153,142,1) 0%, rgba(56,239,125,1) 100%);
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg19 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Quepal.jpg) no-repeat center center fixed;    */
    background: rgb(17,153,142);
    background: linear-gradient(90deg, rgba(17,153,142,1) 0%, rgba(56,239,125,1) 100%);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.bg_20 {
    /* background: url(../assets/Ukraine.jpg) no-repeat center center fixed !important; */
    background: rgb(1,80,249);
    background: linear-gradient(180deg, rgba(1,80,249,1) 0%, rgba(253,248,77,1) 100%);

    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    
}
.shade_scr_vert_line_bg20 { 
    height: 100px;
    width: 5px;  
    margin-right: 10px; 
    /* background: url(../assets/Ukraine.jpg) no-repeat center center fixed;    */
    background: rgb(1,80,249);
    background: linear-gradient(180deg, rgba(1,80,249,1) 0%, rgba(253,248,77,1) 100%);

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;     
}

.yellow_txt {
    color: var(--yellow-txt);
   
}
.white_txt {
    color: var(--wh-text)
}
.blue_txt {
    /* color: var(--btn-bg-blue) !important; */
    color:#110b83 !important;
   
}
.navy_txt {
    color:navy !important;
    font-weight: 500;
}
.dred_txt {
  color:#c53069 !important;
  
}
/* .test {
    color:#ff006a; 
} */
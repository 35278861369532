
     :root {
    --a: 9.1deg;
    --b: calc(var(--a) + 90deg);
    --c: calc(var(--a) + 177deg);
    --d: calc(var(--a) + 270deg);
    } 
    
   
    .char1 { transform: rotate(calc(var(--a) * 1)); }
    .char2 { transform: rotate(calc(var(--a) * 2)); }
    .char3 { transform: rotate(calc(var(--a) * 3)); }
    .char4 { transform: rotate(calc(var(--a) * 4)); }
    .char5 { transform: rotate(calc(var(--a) * 5)); }
    .char6  { transform: rotate(calc(var(--a) * 6)); }
    .char7  { transform: rotate(calc(var(--a) * 7)); }
    .char8  { transform: rotate(calc(var(--a) * 8)); }
    .char9  { transform: rotate(calc(var(--a) * 9)); }
    .char10 { transform: rotate(calc(var(--a) * 10)); }
    .char11 { transform: rotate(calc(var(--a) * 11)); }
    .char12 { transform: rotate(calc(var(--a) * 12)); }
    .char13 { transform: rotate(calc(var(--a) * 13)); }    
    .char14 { transform: rotate(calc(var(--a) * 14)); }
    .char15 { transform: rotate(calc(var(--a) * 15)); }    
    .char16 { transform: rotate(calc(var(--a) * 16)); }
    .char17 { transform: rotate(calc(var(--a) * 17)); }
    .char18 { transform: rotate(calc(var(--a) * 18)); }
    .char19 { transform: rotate(calc(var(--a) * 19)); }
    .char20 { transform: rotate(calc(var(--a) * 20)); }
    .char21 { transform: rotate(calc(var(--a) * 21)); }
    .char22 { transform: rotate(calc(var(--a) * 22)); }
    .char23 { transform: rotate(calc(var(--a) * 23)); }
    .char24 { transform: rotate(calc(var(--a) * 24)); }
    .char25 { transform: rotate(calc(var(--a) * 25)); }
    .char26 { transform: rotate(calc(var(--a) * 26)); }
    .char27 { transform: rotate(calc(var(--a) * 27)); }
    .char28 { transform: rotate(calc(var(--a) * 28)); }
    .char29 { transform: rotate(calc(var(--a) * 29)); }
    .char30 { transform: rotate(calc(var(--a) * 30)); }
    .char31 { transform: rotate(calc(var(--a) * 31)); }
    .char32 { transform: rotate(calc(var(--a) * 32)); }
    .char33 { transform: rotate(calc(var(--a) * 33)); }
    .char34 { transform: rotate(calc(var(--a) * 34)); }
    .char35 { transform: rotate(calc(var(--a) * 35)); }
    .char36 { transform: rotate(calc(var(--a) * 36)); }
    .char37 { transform: rotate(calc(var(--a) * 37)); }
    .char38 { transform: rotate(calc(var(--a) * 38)); }
    .char39 { transform: rotate(calc(var(--a) * 39)); }
    .char40 { transform: rotate(calc(var(--a) * 40)); }
    .char41 { transform: rotate(calc(var(--a) * 41)); }
    .char42 { transform: rotate(calc(var(--a) * 42)); }
    .char43 { transform: rotate(calc(var(--a) * 43)); }
    .char44 { transform: rotate(calc(var(--a) * 44)); }
    .char45 { transform: rotate(calc(var(--a) * 45)); } 
    .char46 { transform: rotate(calc(var(--a) * 46)); }
    .char47 { transform: rotate(calc(var(--a) * 47)); }
    .char48 { transform: rotate(calc(var(--a) * 48)); }
    .char49 { transform: rotate(calc(var(--a) * 49)); }
    .char50 { transform: rotate(calc(var(--a) * 50)); }




.rot_box_wrapper {
    width: 100%;
    height: 30px;
    position: relative;    
}
.rotate_all {
    border-radius: 50%;    
    position: absolute;    
}
.rotate_graph {   
    left: 8px;
    top: -39px;   
}
.rotate_re {   
    left: 10px;
    top: -37px;   
}
.rotate_sett {   
    left: 18px;
    top: -30px;   
}
.rotate_pwr {   
    left: 8px;
    top: -38px;   
}
.rotate_ppl {   
    left: 8px;
    top: -38px;   
}

    
  

    .rotate span {
        font: 11px Monaco, MonoSpace;
        height: 35px;
        position: absolute;
        width: 15px;
        left: 48%;
        top: 20px;
        transform-origin: bottom center;
    }
  
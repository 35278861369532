.page_header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center; 
    background-color:antiquewhite
}

.cards_wrapper {
    width: 100%;
    padding-top: 10px; 
    font-size: larger;  
}


.cards_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    align-items:center;
    margin-bottom: 7px;
    padding: 10px 15px;
    font-weight: bold;
}

.card_item_cont {
  
}
.card_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    background-color:var(--notification-bg);
    border-radius:var(--border-rad);
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 7px;
    transition: 0.8s ease-in-out; 
    line-height: 30px;  
    transition: all 1s ease-in-out;
    height: auto;
    min-height: 140px;
}
.card_item:hover {
    transform: scale(1.10) translateX(3px) ;  
    font-size: large; 
    z-index: 5; 
}


.card_header_letter {
    width: 15%; 
}

.card_header_result_wrapper {
     width: 50%;
}
.card_header_result_topper {
    border-bottom: 1px solid;
    padding: 5px;
    width: 95%;
    margin-left: 10px;
}
.card_header_result_cont {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.card_header_recom {
    width: 100%;
}


.card_item_letter {
width: 17%;
font-size: larger;
}
.card_item_result {
    width: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.card_item_result_f {
    margin-left: 25px;
}
.card_item_recom {
   width: 100%;
}


.reactions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:center;
    padding-left: 20px;
}
.face {
    width: 10%;
    margin-right: 20px;
}
.description {
    width: 100%;
    text-align:center; 
}
.green {
    color:greenyellow;
   
}
.orange {
    color:rgb(226, 219, 85);
}
.yellow {
    color: rgb(255, 159, 99);
}
.traintrain {
    color:rgb(235, 20, 255);
}
.serious {
    color:deeppink;
}


.open_view_item {
    position: fixed;
    height: 96vh;
    width: 794px;
    top: 20px;
    left: 450px;
    cursor:auto;
    z-index: 1;
    outline: 1px solid #FFFFFF;
    box-shadow: 7px 10px 7px 0 rgba(128, 128, 128, 0.705);
    align-items: flex-start;
    padding-top: 50px;
    transition: all 1s ease-in-out;
   
}
.open_view_item:hover {
    transform:none !important;
}
.x_icon {
   position:absolute; 
   top:15px;
   right: 20px;
   outline: 1px solid;
   border-radius:var(--border-rad);
   height: 30px;
   width: 30px;
   display: flex;
   justify-content: center;
   align-items:center;
   cursor: pointer;
   z-index: 2;
}
.open_item {
   
    z-index: 5;
    font-size:x-large;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center;
    background-color:var(--notification-bg);
    border-radius:var(--border-rad);
}
.open_item:hover {
    transform:none !important;
    position: fixed;
}
.line {
    width: 90%;
    height: 2px;
    background-color:#FFFFFF;
    margin: 15px 0;
}
.open_card_header, .open_card_body {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:flex-start;
    padding-left: 20px;
    gap: 30px;
}
.open_card_header {
    gap:65px;
}
.open_card_recom_cont {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
    font-size: large; 
}
.open_card_header_line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:center;
   padding-left: 140px;
   padding-right: 15px;
}
.open_card_recom_exerc {
   
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    padding-left: 40px;
    margin: 20px 0;
    cursor: pointer;
   
}
.open_card_recom_rpt {
    width:180px;
}
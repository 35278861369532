.typing_screen_header {
    margin-top: 30px;
    display: flex;
    align-items:center;
    justify-content: center;
    /* background-color:blueviolet;    */
    width:100%;
}
.typing_screen_header_container {
    display: flex;
    justify-content: space-between; 
    width:762px;
}
.typing_screen_header_box1 { 
  display: flex;
  flex-direction: row; 
  justify-content:flex-start;
}
.typing_screen_header_item {
    width: 155px;
    text-align: left;
}
.typing_screen_header_box2 {
    display: flex;
    flex-direction: row;
    align-items:  flex-end;
    justify-content:flex-end;
    padding-right: 10px;  
}
.lesson_header_three {
    display: flex;
    flex-direction: row;
    color: var(--wh-text);
    font-size: var(--txt-bold-gap);
    font-weight: 600;
}
.timeCounter {
    display: block;
    width: 50px !important;   
}

.lessons_wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;   
    position: relative !important;      
    overflow-x: hidden !important;
    transition: all ease-in-out 1s;
    padding-bottom: 40px;
}
.all_lessons_wrapper {
    height: 100vh;
    width: fit-content;
    min-width: calc(100vw - 450px);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;   
    position: relative !important;      
    overflow-x: hidden !important;
    transition: all ease-in-out 1s;
    padding-bottom: 40px;
    padding-left: 30px;
}
.lessons_left_screen_part {
    width: calc(100vw - 450px - 1000px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: var(--yellow-txt);
    position: fixed;
    top: 0px;
    right: 0px;
   
}

.lesson_list_container {
    /* background-color:blueviolet; */
    margin-top: 110px;
    width: 700px;
    display: flex;
    flex-direction: column;
   
    justify-content: center;
    transition: all ease-in-out 1s;
    font-size: larger;
}

.lesson_list_container h4 {
    color: var(--yellow-txt);
}
.lesson_list_spreadout {
    min-width: 650px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    align-items: center; 
    justify-content: space-between;
    margin-bottom: 10px;
    
}
.lesson_list_arrow {
    /* transform: rotate(180deg) translateY(-17px); */
    transition: all 0.3s linear;
    font-size: xx-large;
    padding-top: 10px;
    transform: rotate(90deg);
}
.lesson_list_arrow_rotate {
    transform: rotate(0deg) ;
    transition: all 0.3s linear;
   
}

.lesson_list_items{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    overflow: hidden;
}
.lesson_list_index {
    min-width: 80px;
    min-height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
    /* background-color: rgb(52, 67, 67); */
}
.lesson_list_descr {
    min-height: 50px;   
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
   
}


.exerc_list {
    margin-left: 100px; 
    display: flex;
    flex-direction: column;
   justify-content: left;
   align-items: left;
    transition: all 1s ease;   
    padding-bottom: 20px;
    min-height: 0;
}
.exerc_list_container {   
    transition: 1s ease-in-out; 
  opacity: 1;
  height: auto;  
  transition: opacity 1.3s ease-in-out, height 1.3s ease-in-out;
}
.exerc_list_container_hidden {
   transition: 1.5s ease-in-out; 
   opacity: 0;
   height: 0;
   overflow: hidden;
   transition: opacity 1.3s ease-in-out, height 1.3s ease-in-out;
}
.locked_content {   
    pointer-events: none;
}
.open_content {   
    pointer-events: all;
    cursor: pointer !important;
    color: #FFFFFF;
}


.exerc_list_header {
    min-width: 200px;
    /* background-color:blueviolet; */   
}

.exerc_list_item {
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color:rgb(47, 56, 53); */
    /* width: 95%; */
}
.future_lesson {
 color:rgb(198, 198, 198);
 cursor:not-allowed;
}

.lock_icon {
   padding: 0 10px 20px 0; 
}
.place_holder {
    width: 26px;
}
.mortorboardIcon {
    padding-top: 10px;
    margin-right: 10px;
}
.results_cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
   
    width: 80px;
}

.displ_inline {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    font-size: larger;
    
}
.wrapper {
    position: relative;
    width: 100vw !important;
    overflow: hidden !important;
}

.note_wrapper {
 width: 100vw; 
 height: 100vh;
 display: flex;
 align-items:center;
 justify-content: center; 
 overflow: hidden;
 position: relative;
}

.note_container {
 width: auto;
 min-width: 690px;
 padding: 0 10px;
 
 height: 85%;
 max-height: 650px;
 display: flex;
 flex-direction: column;
 align-items:center;
 justify-content: flex-start;
 background-color: var(--notification-bg); 
 border-radius: 20px;
 /* overflow-y: auto; */
}
.note_container_rptd {
    height: 95vh !important;
}

 
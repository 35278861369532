.login_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1px; 
    overflow: hidden;   
}
.header_txt {
    margin: 10px 0 10px 0;
    font-size: var(--txt-bold);
    color: var(--yellow-txt);
    position:sticky;
    background-color:var(--notification-bg);
}
.progress_bar {
     width: 60%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;   
}
.progress_bar__step {   
    width: 32%;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
    height: 18px;
    font-size: 10px;
    padding: 1px 0 0 0;
    transition: 0.8s ease-in-out;
}
.progress_bar__step_filled {
    background-color: var(--green-btn);
    border: 1px solid var(--green-btn);
   color: var(--notification-bg);
   font-weight: bold;
   transition: 0.8s ease-in-out;
}
.inputs_section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1px; 
   
    overflow: hidden;
    margin: 0 5px 5px 5px;
    padding-bottom: 5px;
}
.inputs_section__screen_two {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    font-size: var(--txt-normal);
    color: var(--yellow-txt);
    gap:20px
}
.min_margins {
    margin: 10px 0; 
    padding-top: 0px;
}
.rel_div {
   position: relative; 
   width: 100%;
}
.abs_div {
    position: absolute;
    right: 10px;
    top: 55%;
    cursor: pointer;
}
.abs_div_ext {
    position: absolute;
    right: 10px;
    top: 20%;
    cursor: pointer;  
}
.abs_div_G {
    position: absolute;
    right: 45px;
    top: 5%;
    cursor: pointer !important;
    color: var(--notification-bg); 
    font-size: 20px;
    font-weight:bold;
    /* border: 3px solid #213b44; */
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items:center;
    justify-content: center;
    padding-bottom: 8px;
    text-decoration: none;   
}
.no_border {
    border: none;
}
.form_group {  
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 60%;
    margin: 5px 0;
}
.lable_txt {
    font-size: var(--txt-bold-small);
    color: var(--yellow-txt)
}
.lable_lower_txt {
   width: 100%;
  
}
.form_input {
    border-radius: 10px;
    height: 40px;
}
.login_btn {
    border-radius: 10px;
    width: 60%;
    font-size: var(--txt-bold-small);
    margin-top: 5px;
}
.horiz_line {
    margin: 0 0 5px 0;
    width: 45%;
    height: 1px;
    background-color: #FFFFFF;
    border-radius:10px;
    margin-top: 10px;
}
.link {
    cursor: pointer;
    font-size: var(--txt-bold-small);
}
.pdg_btm {
    padding-bottom: 10px;
}
.mgn_btm {
margin-bottom: 25px;
padding-bottom: 10px;
}
.alert_notice {
    font-size: var(--txt-key_board);
    color: rgb(221, 32, 32);
    margin-bottom: 10px;
}
.txt_box {
    width: 60%;
    margin: 30px 0;
    font-size: var(--txt-key_board);
    color: var(--yellow-txt)
}

.login_lowerblock {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 5px;
    margin-top: 35px;
    margin-bottom: 35px;
   
}
.screen_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.btn_next_prev {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
    margin-bottom: 20px;
    transition: 0.5s ease-in-out;
}
.btn_box {
    width: 50%;
    transition: 0.5s ease-in-out;
}
.btn_prev {
    width: 100%;
}

.captcha_wrapper {
    margin-top: 20px; 
}
.captcha_wrapper h5 {
 color: var(--yellow-txt);
}  
.captcha_wrapper input {
    color: var(--notification-bg);
}

.congrats {
    color: var(--yellow-txt);
    font-size: var(--txt-normal);
}
.alert {
font-size: var(--txt-bold-small);
width: 100%;
display: contents;
align-items: center;
justify-content: center;
}

.App {
  width: 100vw;
  height: 100vh;
  text-align: center;
  color: #ffffff; 
  
}








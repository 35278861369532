.login_wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 1px; 
    overflow: hidden;  
}
.header_txt {
    margin: 10px 0 10px 0;
    font-size: var(--txt-bold);
    color: var(--yellow-txt);
    position:sticky;
    background-color:var(--notification-bg);
}
.inputs_section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1px; 
   
    overflow: hidden;
    margin: 0 5px 5px 5px;
    padding-bottom: 5px;
}

.form_lower_portion {
    height: 40%;
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: space-evenly;  
}
.form_group {  
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    width: 60%;
    margin: 5px 0;
}
.lable_txt {
    font-size: var(--txt-bold-small);
    color: var(--yellow-txt)
}
.lable_lower_txt {
   width: 100%;
  
}
.form_input {
    border-radius: 10px;
    height: 40px;
}

.rounded_btn {
    border-radius: 30px;
    font-family: 'Montserrat';   
    font-style: bold; 
    width: 100%;
 }


.btn_text {
    display:block;
    font-size: var(--txt-bold-gap);
}
.btn_text_small {
    
    font-size: var(--txt-bold-small);
}



.login_lowerblock {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 5px;
    margin-top: 35px;
    margin-bottom: 35px;
   
}
.screen_box {
    width: 100%;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}

.captcha_wrapper {
    margin-top: 20px; 
}
.captcha_wrapper h5 {
 color: var(--yellow-txt);
}  
.captcha_wrapper input {
    color: var(--notification-bg);
}

.congrats {
    color: var(--yellow-txt);
    font-size: var(--txt-normal);
    width: 60%;
    margin-bottom: 20%;
}


.btn_next_prev {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:10px;
    margin-bottom: 20px;
    transition: 0.5s ease-in-out;
   
}
.btn_box {
    width: 50%;
    transition: 0.5s ease-in-out; 
}
.btn_prev {
    width: 100%;
}
.login_btn {
    border-radius: 10px;
    width: 60%;
    font-size: var(--txt-bold-small);
    margin-top: 5px;
}
.btn_prev {
    width: 100%;
}